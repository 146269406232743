import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { AppPermissions } from '../../../auth/models/app-permissions';
import { AuthService } from '../../../auth/services/auth.service';

export const authRedirectResolver: ResolveFn<string> = () => {
  const authService = inject(AuthService);

  if (authService.hasRole(AppPermissions.COMPANY)) {
    return 'companies/me';
  } else if (authService.hasRole(AppPermissions.CITYHALL)) {
    return 'cityhalls/me';
  } else if (authService.hasRole(AppPermissions.XUNTA)) {
    return 'xunta';
  } else if (authService.hasRole(AppPermissions.TECHNICALOFFICE)) {
    return 'admin';
  } else {
    return 'not-found';
  }
};
