import { Routes } from '@angular/router';
import { authGuard } from './auth/guards';
import { authRedirectResolver } from './shared/Utils/resolvers/auth-redirect.resolver';
import { mainPagesRoutes } from './main/pages/main-pages.routes';
import { adminPagesRoutes } from './admin/pages/admin-pages.routes';

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    resolve: {
      redirectTo: authRedirectResolver,
    },
    runGuardsAndResolvers: 'always',
    loadComponent: async () =>
      (await import('./main/main-layout/main-layout.component'))
        .MainLayoutComponent,
    children: mainPagesRoutes,
  },
  {
    path: 'admin',
    title: 'Admin',
    canActivate: [authGuard],
    data: {
      roles: ['AppPermissions.TECHNICALOFFICE'],
    },
    loadComponent: async () =>
      (await import('./admin/admin-layout/admin-layout.component'))
        .AdminLayoutComponent,
    children: adminPagesRoutes,
  },
  {
    path: 'not-found',
    title: 'Not Found',
    loadComponent: async () =>
      (await import('./error404/error404.component')).Error404Component,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
