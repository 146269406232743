import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { ProfileRoles } from '../models/app-permissions';
import { AuthService } from '../services/auth.service';

export const roleGuard: CanActivateFn = (route) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const expectedRoles = route.data['roles'] as ProfileRoles[];
  const userRoles = authService.getUserRoles();

  if (userRoles && expectedRoles.some((role) => userRoles.includes(role))) {
    return true;
  }

  router.navigate(['/not-found']);
  return false;
};
