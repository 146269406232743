import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
  const authService = inject(AuthService);
  const loggedIn = await authService.isLoggedIn();

  if (!loggedIn) {
    authService.redirectToLogin();
    return false;
  }

  return true;
};
